



































































































































































































































































































import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TrendingProducts',

  methods: {
    getLocalePath(path) {
      return decodeURIComponent(this.localePath(path));
    }
  }
})
